import React, { useState } from "react";
import styled from "styled-components";
import Select from "../../Select";
import ParticipantNetworkQualityStats from "./ParticipantNetworkQualityStats";

const Wrapper = styled.div`
  margin: 20px 0;
`;

const NetworkQualityStats = ({ quiz }) => {
  const [selectedPlayer, setSelectedPlayer] = useState(null);

  const selectOptions = quiz.players
    .map((player) => ({
      value: player.id,
      label: player.name,
    }))
    .sort((a, b) => (a.label > b.label ? 1 : -1));

  const handlePlayerChange = (option) => {
    const newPlayer = quiz.players.find((player) => player.id === option.value);
    setSelectedPlayer(newPlayer);
  };

  return (
    <Wrapper>
      <div>Network Quality Stats</div>
      <div>For quiz overall: {quiz.networkQualityLevelAverage}</div>
      <div>
        Player: <Select options={selectOptions} onChange={handlePlayerChange} />
      </div>
      {selectedPlayer && (
        <ParticipantNetworkQualityStats
          participant={selectedPlayer}
          quiz={quiz}
        />
      )}
    </Wrapper>
  );
};

export default NetworkQualityStats;
