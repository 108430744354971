import React, { useEffect, useState } from "react";
import PageWrapper from "../components/PageWrapper";
import queryString from "query-string";
import Analytics from "../components/Analytics";

export default function analytics() {
  const [quizId, setQuizId] = useState();

  useEffect(() => {
    const { quizId } = queryString.parse(location.search);
    setQuizId(quizId);
  }, []);

  return <PageWrapper>{quizId && <Analytics quizId={quizId} />}</PageWrapper>;
}
