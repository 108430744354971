import React from "react";
import getLogger from "../../../util/getLogger";
import { Line, LineChart, Tooltip, XAxis, YAxis } from "recharts";

// const SectionHeader = styled.div`
//   margin: 20px 0 10px;
//   font-weight: bold;
// `;

const ParticipantNetworkStats = ({ participant, quiz }) => {
  const logger = getLogger(`ParticipantNetworkStats: ${participant.id}`);

  const data = JSON.parse(participant.networkQualityStats);
  logger.debug("data", data);

  return (
    <div>
      <div>
        From the perspective of participant: {participant.name}: average is{" "}
        {participant.networkQualityLevelAverage}
      </div>
      <div>
        <LineChart width={800} height={300} data={data}>
          <XAxis dataKey="time" />
          <YAxis type="number" domain={[0, 5]} ticks={[0, 1, 2, 3, 4, 5]} />
          <Tooltip />
          {quiz.hosts.map((host) => (
            <Line key={host.id} dataKey={host.id} />
          ))}
          {quiz.players.map((player) => (
            <Line key={player.id} dataKey={player.id} />
          ))}
        </LineChart>

        {/*<SectionHeader>Hosts:</SectionHeader>*/}
        {/*{quiz.hosts.map((host) => (*/}
        {/*  <div key={host.id}>{host.name}</div>*/}
        {/*))}*/}
        {/*<SectionHeader>Players:</SectionHeader>*/}
        {/*{quiz.players.map((player) => (*/}
        {/*  <div key={player.id}>{player.name}</div>*/}
        {/*))}*/}
      </div>
    </div>
  );
};

export default ParticipantNetworkStats;
