import React from "react";
import { gql, useQuery } from "@apollo/client";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import NetworkQualityStats from "./NetworkQualityStats";
import styled from "styled-components";
import SurveyResults from "../Survey/SurveyResults";
import DarkPageHeading from "../DarkPageHeading";

const GET_QUIZ = gql`
  query GetQuiz($id: ID) {
    quiz: getQuiz(id: $id) {
      name
      publicId
      owner {
        id
        username
      }
      started
      networkQualityLevelAverage
      hosts {
        id
        name
        coHost
        eventLog
        networkQualityLevelAverage
        networkQualityStats
      }
      players {
        id
        name
        createdDate
        leader
        eventLog
        networkQualityLevelAverage
        networkQualityStats
        team {
          id
        }
      }
      teams {
        id
        name
        emoji
        score
        ranking
        players {
          name
          leader
        }
      }
      surveyResponses {
        submittedBy
        happiness
        npsScore
        improvementAdvice
      }
    }
  }
`;

const TabsWrapper = styled.div`
  margin-top: 20px;
  width: 100%;
`;

const Analytics = ({ quizId }) => {
  const { data } = useQuery(GET_QUIZ, {
    variables: {
      id: quizId,
    },
  });

  if (!data || !data.quiz) {
    return null;
  }

  const quiz = data.quiz;
  const name = quiz.name;
  const publicId = quiz.publicId;
  const hosts = quiz.hosts;
  const mainHost = hosts.find((host) => !host.coHost);
  const coHost = hosts.find((host) => host.coHost);
  const players = quiz.players;
  const teams = quiz.teams;

  const playersByName = players.sort((a, b) => (a.name > b.name ? 1 : -1));
  const teamsByScore = teams.sort((a, b) => (a.score < b.score ? 1 : -1));

  return (
    <>
      <DarkPageHeading>
        Quiz Analytics: {name} ({publicId})
      </DarkPageHeading>
      {mainHost && (
        <div>
          Hosted by {mainHost.name}{" "}
          {coHost && `and co-hosted by ${coHost.name}`}
        </div>
      )}
      <div>{players.length} players</div>
      <div>{teams.length} teams</div>

      <TabsWrapper>
        <Tabs>
          <TabList>
            <Tab>Players</Tab>
            <Tab>Final Scores</Tab>
            <Tab>Feedback</Tab>
            <Tab>Network Quality Stats</Tab>
          </TabList>

          <TabPanel>
            {playersByName.map((player) => (
              <div key={player.id}>{player.name}</div>
            ))}
          </TabPanel>
          <TabPanel>
            {teamsByScore.map((team) => {
              const teamPlayersStr = team.players
                .sort((a, b) => (a.name > b.name ? 1 : -1))
                .sort((a) => (a.leader ? 1 : 0))
                .map((player) => player.name)
                .join(", ");
              return (
                <div key={team.id}>
                  {team.ranking}. {team.emoji} {team.name}: {team.score} points
                  ({teamPlayersStr})
                </div>
              );
            })}
          </TabPanel>
          <TabPanel>
            <SurveyResults quiz={quiz} dark />
          </TabPanel>
          <TabPanel>
            <NetworkQualityStats quiz={quiz} />
          </TabPanel>
        </Tabs>
      </TabsWrapper>
    </>
  );
};

export default Analytics;
